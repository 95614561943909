import React from "react"
import { PageProps } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { FaRegEnvelope } from "react-icons/fa";

type DataProps = {
  site: {}
}

const IndexPage: React.FC<PageProps<DataProps>> = ({ data, path }) => (
  <Layout>
    <SEO title="In Progress" />
    <div className="profile-image"></div>
    <h1>IN PROGRESS</h1>
    <span><FaRegEnvelope /> me@honzanoll.cz</span>
  </Layout>
)

export default IndexPage
